//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STable, XCard } from '@/components'
import { flowableDefinitionPage, flowableDefinitionDelete, flowableDefinitionExport, flowableDefinitionMapping,
  flowableDefinitionSuspend, flowableDefinitionActive } from '@/api/modular/flowable/defenitionManage'
import { flowableCategoryList } from '@/api/modular/flowable/categoryManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import configForm from './configForm'
import AddShortcutForm from './addShortcutForm'
import tracking from './tracking'
export default {
  components: {
    XCard,
    AddShortcutForm,
    STable,
    configForm,
    tracking
  },
  data () {
    return {
      advanced: false,
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [
        {
          title: '定義名稱',
          dataIndex: 'name'
        },
        {
          title: '定義KEY',
          dataIndex: 'key'
        },
        {
          title: '版本',
          dataIndex: 'version',
          scopedSlots: { customRender: 'version' }
        },
        {
          title: '流程分類',
          dataIndex: 'categoryName'
        },
        {
          title: '是否掛起',
          dataIndex: 'suspended',
          scopedSlots: { customRender: 'suspended' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '200px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加載數據方法 必須為 Promise 對象
      loadData: parameter => {
        return flowableDefinitionPage(Object.assign(parameter, this.queryParam)).then((res) => {
          return res.data
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      flowableCategoryListData: [],
      suspendedDropDown: [],
      cardLoading: false,
      // 卡片切換
      indexPageShow: true,
      configFormShow: false
  }
  },
  created () {
    this.sysDictTypeDropDown()
    this.flowableCategoryList()
  },

  methods: {

    openConfig (record) {
      this.indexPageShow = false
      this.configFormShow = true
      this.$refs.configForm.config(record)
    },
    closeConfig () {
      this.indexPageShow = true
      this.configFormShow = false
    },
    suspendedFilter (suspended) {
      // eslint-disable-next-line eqeqeq
      const values = this.suspendedDropDown.filter(item => item.code == suspended.toString())
      if (values.length > 0) {
        return values[0].value
      }
    },
    /**
     * 獲取字典數據
     */
    sysDictTypeDropDown () {
      sysDictTypeDropDown({ code: 'suspended_status' }).then((res) => {
        this.suspendedDropDown = res.data
      })
    },
    /**
     * 獲取流程類型
     */
    flowableCategoryList () {
      flowableCategoryList().then((res) => {
        this.flowableCategoryListData = res.data
      })
    },
    /**
     * 導出
     */
    flowableDefinitionExport (record) {
      this.cardLoading = true
      flowableDefinitionExport({ id: record.id }).then((res) => {
        this.cardLoading = false
        this.downloadfile(res)
      // eslint-disable-next-line handle-callback-err
      }).catch((err) => {
        this.cardLoading = false
        this.$message.error('導出錯誤：獲取文件流錯誤')
      })
    },
    downloadfile (res) {
      var blob = new Blob([res.data], { type: 'application/octet-stream;charset=UTF-8' })
      var contentDisposition = res.headers['content-disposition']
      var patt = new RegExp('filename=([^;]+\\.[^\\.;]+);*')
      var result = patt.exec(contentDisposition)
      var filename = result[1]
      var downloadElement = document.createElement('a')
      var href = window.URL.createObjectURL(blob) // 創建下載的鏈接
      var reg = /^["](.*)["]$/g
      downloadElement.style.display = 'none'
      downloadElement.href = href
      downloadElement.download = decodeURI(filename.replace(reg, '$1'))
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(href)
    },
    /**
     * 映射
     */
    flowableDefinitionMapping (record) {
      this.cardLoading = true
      flowableDefinitionMapping({ id: record.id }).then((res) => {
        this.cardLoading = false
        if (res.success) {
          this.$message.success('映射成功')
        } else {
          this.$message.error('映射失敗：' + res.message)
        }
      })
    },
    /**
     * 掛起激活
     */
    suspendedChange (code, record) {
      this.cardLoading = true
      if (code) {
        flowableDefinitionActive(record).then((res) => {
          this.cardLoading = false
          if (res.success) {
            this.$message.success('激活成功')
            this.$refs.table.refresh()
          } else {
            this.$message.error('激活失敗：' + res.message)
          }
        })
      } else {
        flowableDefinitionSuspend(record).then((res) => {
          this.cardLoading = false
          if (res.success) {
            this.$message.success('掛起成功')
            this.$refs.table.refresh()
          } else {
            this.$message.error('掛起失敗：' + res.message)
          }
        })
      }
    },
    /**
     * 刪除
     * @param record
     */
    flowableDefinitionDelete (record) {
      flowableDefinitionDelete(record).then((res) => {
        if (res.success) {
          this.$message.success('刪除成功')
          this.$refs.table.refresh()
        } else {
          this.$message.error('刪除失敗：' + res.message)
        }
      }).catch((err) => {
        this.$message.error('刪除錯誤：' + err.message)
      })
    },
    handleOk () {
      this.$refs.table.refresh()
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
